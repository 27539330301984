@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

.rounded-t-5 {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

@media (max-width: 550px) {
  .rounded-tr-lg-0 {
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0rem;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
